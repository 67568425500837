import { FC, useState, useMemo, memo, useRef, useCallback } from 'react';
import { useLegacyEffect } from 'hooks/useLegacyEffect';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useSMPlatforms } from 'hooks/useSMPlatforms';
import { useParams } from 'react-router-dom';
import { useBlockLayout, useSortBy, useResizeColumns, useTable } from 'react-table';
import { useVirtual } from 'react-virtual';
import { ResponsiveContainer, BarChart, Bar, PieChart, Pie, Cell, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Sector } from 'recharts';
import { isEqual, maxBy, orderBy, sortBy, uniqBy } from 'lodash';
import moment from 'moment';
import { colors } from 'variables';
import cn from 'classnames';
import { Breadcrumbs, RCDate, RSelect, SwitchCase, TableHeaderSort, TableCellTruncate, TableResizer, TableHeaderTruncate } from 'shared';
import {
  campaignClientActions,
  createCampaignClientReportDataThunk,
  selectCampaignClient,
  selectCampaignReportDataClient,
  selectCampaignClientReportIds,
  selectCampaignReportSettingsPlatformClient,
  selectCampaignReportSettingsTableFilterClient,
  selectCampaignReportSettingsTableFilterSortByClient,
  selectCampaignReportSettingsTableFilterResizingClient,
} from '../reducer';
import { getTotalForColumn, PlatformSelectCustomSingleValue, PlatformSelectOption, platformSelectStyles } from 'pages/Influencers/tabs/InfluencersTab';
import { SingleValue } from 'react-select';
import { closestNumber, dateSort, formatNumber, getTooltip, numberSort, publicationDateFormat, randomColor, replaceSpaceToUnderscore } from 'utils/helper';
import { ReportEmpty } from '../empties/ReportEmpty';
import { PopupDescription } from 'components/PopupDescription';
import { LanguageFullName } from 'components/LanguageFullName';
import { CountryFullName } from 'components/CountryFullName';
import { ExportMenu } from 'components/ExportMenu';
import {
  YoutubeLogoIcon,
  UserSmallSolidIcon,
  EyeOpenSmallSolidIcon,
  FlagSmallSolidIcon,
  HeartSmallSolidIcon,
  MessageSmallSolidIcon,
  LinkIcon,
  TwitchLogoIcon,
  AchievementSmallSolidIcon,
  PlaySmallSolidIcon,
  ClockSmallSolidIcon,
  AscendingChartIcon,
  DescendingChartIcon,
} from 'assets/icons';

import './styles.css';

const chartData: any[] = [
  {
    name: 'Page A',
    audience: 100,
    total_views: 200,
    avg_views: 150,
    score: 200,
    likes: 100,
    comments: 250,
  },
  {
    name: 'Page B',
    audience: 400,
    total_views: 50,
    avg_views: 300,
    score: 50,
    likes: 500,
    comments: 50,
  },
  {
    name: 'Page C',
    audience: 200,
    total_views: 500,
    avg_views: 100,
    score: 600,
    likes: 200,
    comments: 750,
  },
  {
    name: 'Page D',
    audience: 600,
    total_views: 300,
    avg_views: 300,
    score: 200,
    likes: 700,
    comments: 450,
  },
  {
    name: 'Page E',
    audience: 300,
    total_views: 600,
    avg_views: 500,
    score: 500,
    likes: 400,
    comments: 600,
  },
];

const _tableData: any[] = [];

const youtubeColumns = [
  {
    Header: 'Name',
    accessor: 'channel_name',
    Cell: ({ row, value }: any) => (
      <div className="flex items-center space-x-2 overflow-hidden">
        <TableCellTruncate value={value} />
        <a href={row.original.video_final} target="_blank" rel="noopener">
          <LinkIcon />
        </a>
      </div>
    ),
    Footer: 'Total',
    width: 220,
    label: 'Name',
  },
  {
    Header: 'Video Title',
    accessor: 'video_name',
    Cell: ({ value }: any) => <TableCellTruncate value={value} />,
    width: 130,
    label: 'Video Title',
  },
  {
    Header: 'Video URL',
    accessor: 'video_final',
    Cell: ({ value }: any) => (
      <TableCellTruncate
        value={
          <a href={value} className="text-blue-b1 visited:text-purple-600" target="_blank" rel="noopener">
            {value}
          </a>
        }
      />
    ),
    width: 130,
    label: 'Video URL',
  },
  {
    Header: 'Content format',
    accessor: 'content_format',
    Cell: ({ value }: any) => <TableCellTruncate value={value} />,
    width: 130,
    label: 'Content format',
  },
  {
    Header: 'Theme',
    accessor: 'theme',
    Cell: ({ value }: any) => <TableCellTruncate value={value} />,
    width: 130,
    label: 'Theme',
  },
  {
    Header: 'Price',
    accessor: 'price',
    Cell: ({ value }: any) => <TableCellTruncate value={formatNumber(value, '$0,0.[00]', 'float')} />,
    Footer: (info: any) => {
      return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'price'), '$0,0.[00]', 'float')} />, [info.rows]);
    },
    width: 130,
    sortType: numberSort,
    label: 'Price',
  },
  {
    Header: 'Subscribers',
    accessor: 'subscribers',
    Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
    Footer: (info: any) => {
      return useMemo(
        () => <TableCellTruncate value={formatNumber(getTotalForColumn(uniqBy(info.rows, 'original.youtube_channel_id'), 'subscribers'), '0,0')} />,
        [info.rows]
      );
    },
    width: 130,
    sortType: numberSort,
    label: 'Subscribers',
  },
  {
    Header: 'Avg Views',
    accessor: 'median_views_30',
    Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
    Footer: (info: any) => {
      return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'median_views_30'), '0,0')} />, [info.rows]);
    },
    width: 130,
    sortType: numberSort,
    label: 'Avg Views',
  },
  {
    Header: 'Actual Views',
    accessor: 'video_views',
    Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
    Footer: (info: any) => {
      return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'video_views'), '0,0')} />, [info.rows]);
    },
    width: 130,
    sortType: numberSort,
    label: 'Actual Views',
  },
  {
    Header: 'True Reach',
    accessor: 'true_reach',
    Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0.[00]%', 'float')} />,
    Footer: (info: any) => {
      return useMemo(
        () => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'true_reach') / info.rows.length, '0.[00]%', 'float')} />,
        [info.rows]
      );
    },
    width: 130,
    sortType: numberSort,
    label: 'True Reach',
  },
  {
    Header: 'CPM',
    accessor: 'cpm',
    Cell: ({ value }: any) => <TableCellTruncate value={formatNumber(value, '$0,0.[00]', 'float')} />,
    Footer: (info: any) => {
      return useMemo(
        () => (
          <TableCellTruncate
            value={formatNumber(getTotalForColumn(info.rows, 'cpm') / (info.rows.filter((d: any) => d.values.cpm).length || 1), '$0,0.[00]', 'float')}
          />
        ),
        [info.rows]
      );
    },
    width: 130,
    sortType: numberSort,
    label: 'CPM',
  },
  {
    Header: 'Likes',
    accessor: 'video_likes',
    Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
    Footer: (info: any) => {
      return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'video_likes'), '0,0')} />, [info.rows]);
    },
    width: 130,
    sortType: numberSort,
    label: 'Likes',
  },
  {
    Header: 'Clicks',
    accessor: 'clicks',
    Cell: (row: any) => <TableCellTruncate value={row.value} />,
    Footer: (info: any) => {
      return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'clicks'), '0,0')} />, [info.rows]);
    },
    width: 130,
    sortType: numberSort,
    label: 'Clicks',
  },
  {
    Header: 'Comments',
    accessor: 'video_comments',
    Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
    Footer: (info: any) => {
      return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'video_comments'), '0,0')} />, [info.rows]);
    },
    width: 130,
    sortType: numberSort,
    label: 'Comments',
  },
  {
    Header: 'Eng. Score',
    accessor: 'engagement_score',
    Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0.[00]%', 'float')} />,
    Footer: (info: any) => {
      return useMemo(
        () => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'engagement_score') / info.rows.length, '0.[00]%', 'float')} />,
        [info.rows]
      );
    },
    width: 130,
    sortType: numberSort,
    label: 'Eng. Score',
  },
  {
    Header: 'Publication Date',
    accessor: 'publication_date',
    Cell: ({ value }: any) => <TableCellTruncate value={value ? moment(value).format('MMM DD-YY') : ''} />,
    width: 190,
    sortType: dateSort,
    label: 'Publication Date',
  },
  {
    Header: 'Language',
    accessor: 'language',
    width: 130,
    Cell: ({ value }: any) => <LanguageFullName language={value} />,
    label: 'Language',
  },
  {
    Header: 'Country',
    accessor: 'country',
    width: 130,
    Cell: ({ value }: any) => <CountryFullName country={value} />,
    label: 'Country',
  },
].map((d: any) => ({
  ...d,
  tooltip: getTooltip('table', 'youtube', d.accessor),
}));

const twitchColumns = [
  {
    Header: 'Name',
    accessor: 'channel_name',
    Cell: ({ row, value }: any) => (
      <div className="flex items-center space-x-2 overflow-hidden">
        <TableCellTruncate value={value} />
        <a href={row.original.channel_url} target="_blank" rel="noopener">
          <LinkIcon />
        </a>
      </div>
    ),
    Footer: 'Total',
    width: 220,
    label: 'Name',
  },
  {
    Header: 'Stream Title',
    accessor: 'title',
    Cell: ({ value }: any) => <TableCellTruncate value={value} />,
    width: 130,
    label: 'Stream Title',
  },
  {
    Header: 'Content format',
    accessor: 'content_format',
    Cell: ({ value }: any) => <TableCellTruncate value={value} />,
    width: 130,
    label: 'Content format',
  },
  {
    Header: 'Theme',
    accessor: 'theme',
    Cell: ({ value }: any) => <TableCellTruncate value={value} />,
    width: 130,
    label: 'Theme',
  },
  {
    Header: 'Price',
    accessor: 'price',
    Cell: ({ value }: any) => <TableCellTruncate value={formatNumber(value, '$0,0.[00]', 'float')} />,
    Footer: (info: any) => {
      return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'price'), '$0,0.[00]', 'float')} />, [info.rows]);
    },
    width: 130,
    sortType: numberSort,
    label: 'Price',
  },
  {
    Header: 'Subscribers',
    accessor: 'subscribers',
    Cell: ({ value }: any) => <TableCellTruncate value={formatNumber(value, '0,0')} />,
    Footer: (info: any) => {
      return useMemo(
        () => <TableCellTruncate value={formatNumber(getTotalForColumn(uniqBy(info.rows, 'original.twitch_channel_id'), 'subscribers'), '0,0')} />,
        [info.rows]
      );
    },
    width: 130,
    sortType: numberSort,
    label: 'Subscribers',
  },
  {
    Header: 'Average CCV (last 20 streams)',
    accessor: 'aaccv_20',
    Cell: ({ value }: any) => <TableCellTruncate value={formatNumber(value, '0,0')} />,
    Footer: (info: any) => {
      return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'aaccv_20'), '0,0')} />, [info.rows]);
    },
    width: 130,
    sortType: numberSort,
    label: 'Average CCV (last 20 streams)',
  },
  {
    Header: 'Average Peak Viewers (last 20 streams)',
    accessor: 'average_peak_20',
    Cell: ({ value }: any) => <TableCellTruncate value={formatNumber(value, '0,0')} />,
    Footer: (info: any) => {
      return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'average_peak_20'), '0,0')} />, [info.rows]);
    },
    width: 130,
    sortType: numberSort,
    label: 'Average Peak Viewers (last 20 streams)',
  },
  {
    Header: 'Average Stream Duration (last 20 streams), h',
    accessor: 'average_stream_duration_20',
    Cell: ({ value }: any) => <TableCellTruncate value={formatNumber(value != null ? +value / 3600 : null, '0,0.[0]', 'float')} />,
    Footer: (info: any) => {
      return useMemo(
        () => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'average_stream_duration_20') / 3600, '0,0.[0]', 'float')} />,
        [info.rows]
      );
    },
    width: 130,
    sortType: numberSort,
    label: 'Average Stream Duration (last 20 streams), h',
  },
  {
    Header: 'CPVH',
    accessor: 'cpvh',
    Cell: ({ value }: any) => <TableCellTruncate value={formatNumber(value, '$0,0.[00]', 'float')} />,
    Footer: (info: any) => {
      return useMemo(
        () => (
          <TableCellTruncate
            value={formatNumber(getTotalForColumn(info.rows, 'cpvh') / (info.rows.filter((d: any) => d.values.cpvh).length || 1), '$0,0.[00]', 'float')}
          />
        ),
        [info.rows]
      );
    },
    width: 130,
    sortType: numberSort,
    label: 'CPVH',
  },
  {
    Header: 'Actual Average Viewers',
    accessor: 'avg_viewers',
    Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
    Footer: (info: any) => {
      return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'avg_viewers'), '0,0')} />, [info.rows]);
    },
    width: 130,
    sortType: numberSort,
    label: 'Actual Average Viewers',
  },
  {
    Header: 'Actual Peak Viewers',
    accessor: 'peak_viewers',
    Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
    Footer: (info: any) => {
      return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'peak_viewers'), '0,0')} />, [info.rows]);
    },
    width: 130,
    sortType: numberSort,
    label: 'Actual Peak Viewers',
  },
  {
    // Header: 'Actual Stream Duration, h',
    Header: 'Actual Stream Duration, h',
    accessor: 'duration',
    Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value != null ? +row.value / 3600 : null, '0,0.[0]', 'float')} />,
    Footer: (info: any) => {
      return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'duration') / 3600, '0,0.[0]', 'float')} />, [info.rows]);
    },
    width: 130,
    sortType: numberSort,
    label: 'Actual Stream Duration, h',
  },
  {
    Header: 'Clicks',
    accessor: 'clicks',
    Cell: (row: any) => <TableCellTruncate value={row.value} />,
    Footer: (info: any) => {
      return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'clicks'), '0,0')} />, [info.rows]);
    },
    width: 130,
    sortType: numberSort,
    label: 'Clicks',
  },
  {
    Header: 'Publication Date',
    accessor: 'publication_date',
    Cell: ({ value }: any) => <TableCellTruncate value={value ? publicationDateFormat(value) : ''} />,
    width: 190,
    sortType: dateSort,
    label: 'Publication Date',
  },
  {
    Header: 'Language',
    accessor: 'language',
    width: 130,
    Cell: ({ value }: any) => <LanguageFullName language={value} />,
    label: 'Language',
  },
  {
    Header: 'Country',
    accessor: 'country',
    width: 130,
    Cell: ({ value }: any) => <CountryFullName country={value} />,
    label: 'Country',
  },
].map((d: any) => ({
  ...d,
  tooltip: getTooltip('table', 'twitch', d.accessor),
}));

interface IReportProps {
  // selectedRows: any[]
}

export const Report: FC<IReportProps> = () => {
  const { campaignId } = useParams();

  const dispatch = useAppDispatch();
  const campaign = useAppSelector(selectCampaignClient);
  const reportIds = useAppSelector(selectCampaignClientReportIds);
  const reportData = useAppSelector(selectCampaignReportDataClient);
  const platform = useAppSelector(selectCampaignReportSettingsPlatformClient);

  const platformSelectOptions = useSMPlatforms(campaign);

  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [dates, setDates] = useState<any[]>([] as any[]);

  const isEmpty =
    !Object.keys(reportData).length ||
    !Object.keys(reportData.general).length ||
    // !reportData.campaignPerformance.length ||
    !reportData.statsByChannels.length ||
    !reportData.tableData.length;

  const handleHideCalendar = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setShowCalendar(false);
  };

  const handleSaveCalendar = (e: any, values: RCDate) => {
    e.preventDefault();
    e.stopPropagation();
    setDates(values as any[]);
    setShowCalendar(false);
  };

  const handleSelectChange = (newValue: SingleValue<any>) => {
    if (campaignId)
      dispatch(
        createCampaignClientReportDataThunk({
          campaign_id: campaignId || '',
          data: {
            sm_platform: newValue.value,
            selected_ids: reportIds,
            start_date: dates[0] ? moment(dates[0]).toISOString() : '',
            end_date: dates[1] ? moment(dates[1]).toISOString() : '',
          },
        })
      )
        .unwrap()
        .then(() =>
          dispatch(
            campaignClientActions.setReportSettings({
              platform: newValue.value,
            })
          )
        );
  };

  const exportColumns = useMemo(() => {
    return (platform === 'youtube' ? youtubeColumns : twitchColumns).map((d: any) => ({
      key: d.accessor,
      header: d.label,
    }));
  }, [platform]);

  const exportData = useMemo(() => {
    if (!reportData?.tableData) return [];
    return reportData.tableData.map((d: any) => {
      const obj: any = {};
      exportColumns.forEach((dd: any) => {
        obj[dd.key] = d[dd.key];
      });
      return obj;
    });
  }, [reportData?.tableData, exportColumns, platform]);

  useLegacyEffect(() => {
    if (campaignId)
      dispatch(
        createCampaignClientReportDataThunk({
          campaign_id: campaignId || '',
          data: {
            sm_platform: platform,
            selected_ids: reportIds,
            start_date: dates[0] ? moment(dates[0]).toISOString() : '',
            end_date: dates[1] ? moment(dates[1]).toISOString() : '',
          },
        })
      );
  }, [campaignId, reportIds, dates]);

  return (
    <>
      <div className="p-6 pb-0">
        <div className="flex flex-col mb-6 sm:mb-0 sm:flex-row sm:items-start sm:justify-between">
          <div>
            <h1 className="text-2xl leading-[42px] font-semibold truncate">Report</h1>
            <Breadcrumbs
              paths={[
                { name: `Campaigns`, to: `/campaigns` },
                {
                  name: `${campaign.campaign_name}`,
                  to: `/campaigns/${campaign.campaign_id}`,
                },
                {
                  name: `Report`,
                  to: '',
                },
              ]}
            />
          </div>
          {/* {isEmpty ? null : ( */}
          <div className="flex flex-wrap">
            {/* <div
                className="relative flex items-center space-x-1 cursor-pointer"
                onClick={() => setShowCalendar(true)}
              >
                <CalendarRange
                  show={showCalendar}
                  defaultValue={[
                    new Date(dates[0] || new Date()),
                    new Date(dates[1] || new Date()),
                  ]}
                  hide={handleHideCalendar}
                  save={handleSaveCalendar}
                />
                <p className="text-black-b1 text-sm font-semibold">
                  {moment(dates[0]).format('MMM DD')}
                </p>
                <p className="text-black-b1 text-sm font-semibold"> - </p>
                <p className="text-black-b1 text-sm font-semibold">
                  {moment(dates[1]).format('MMM DD')}
                </p>
                <ArrowDownBigIcon className="!ml-3" />
              </div> */}
            {/* <button
                type="button"
                className="inline-flex items-center !ml-3 h-[42px] rounded-[4px] border border-transparent px-2.5 text-sm leading-6 font-semibold text-blue-b1 focus:outline-none focus:ring-0 focus:ring-blue-b1 focus:ring-offset-0"
              >
                <ShareIcon className="mr-2" aria-hidden="true" />
                Share
              </button> */}
            <ExportMenu buttonClass="mr-4" data={exportData} columns={exportColumns} filename={`${replaceSpaceToUnderscore(campaign.campaign_name)}_report`} />
            <div className="relative">
              <RSelect
                className="w-[150px]"
                styles={platformSelectStyles}
                defaultValue={platformSelectOptions[platform]}
                name="select-channels"
                isSearchable={false}
                isClearable={false}
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
                menuShouldScrollIntoView={false}
                options={Object.keys(platformSelectOptions)
                  .filter((d: any) => d !== platform)
                  .map((d: any) => platformSelectOptions[d])}
                components={{
                  ClearIndicator: () => null,
                  IndicatorSeparator: () => null,
                  Option: PlatformSelectOption,
                  SingleValue: PlatformSelectCustomSingleValue,
                }}
                onChange={handleSelectChange}
              />
              {reportData?.tableData?.length ? (
                <span className="absolute -top-2 -right-2 min-w-[1.5rem] h-[1.5rem] inline-flex items-center justify-center rounded-full px-1.5 text-xs font-medium text-blue-b1 bg-[#ece8fd]">
                  {reportData.tableData.length}
                </span>
              ) : null}
            </div>
          </div>
          {/* )} */}
        </div>
      </div>

      {isEmpty ? (
        <ReportEmpty />
      ) : (
        <SwitchCase value={platform}>
          {{
            youtube: <YoutubeReport />,
            twitch: <TwitchReport />,
          }}
        </SwitchCase>
      )}
    </>
  );
};

export const YoutubeReport: FC = () => {
  const campaign = useAppSelector(selectCampaignClient);
  const { general, campaignPerformance, statsByChannels, tableData } = useAppSelector(selectCampaignReportDataClient);

  const parsedPerformanceChartData: any[] = useMemo(
    () =>
      sortBy(campaignPerformance, [(o) => moment(o.date).format('YYYY-MM-DD')]).map((d: any) => {
        return {
          ...d,
          date: moment(d.date).unix(),
          video_views: +d.video_views,
        };
      }),
    [campaignPerformance]
  );

  const interval = Math.floor(parsedPerformanceChartData.length / 5);
  const lineChartTicks = [];

  if (interval > 0) {
    for (let i = 1; i < 5; i++) {
      lineChartTicks.push(parsedPerformanceChartData[i * interval].date);
    }
  }

  const performanceLeftMargin = useMemo(() => (maxBy(parsedPerformanceChartData, 'video_views')?.video_views > 999 ? -10 : -20), [parsedPerformanceChartData]);

  const pieData = useMemo(
    () =>
      orderBy(
        statsByChannels.map((d: any) => ({
          ...d,
          true_reach: +d.true_reach * 100,
          engagement_score: +d.engagement_score * 100,
          cpm: +d.cpm,
          video_views_ratio: +d.video_views_ratio,
          video_views: +d.video_views,
          color: randomColor(),
        })),
        (d: any) => d.video_views,
        ['desc']
      ),
    [statsByChannels]
  );

  const columns = useMemo(() => youtubeColumns, []);

  return (
    <div className="p-6 pt-0 overflow-auto">
      <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-3">
        <div className="p-6 rounded-[4px] bg-white">
          <p className="text-base font-semibold text-black-b1">General info</p>
          <div className="mt-10 grid grid-cols-2 gap-6 text-sm leading-6">
            <div className="text-gray-gm">Campaign Name:</div>
            <div className="text-black-b1 truncate" title={campaign.campaign_name}>
              {campaign.campaign_name}
            </div>
            <div className="text-gray-gm">Report Date: </div>
            <div className="text-black-b1">{moment().format('MMM-DD-YYYY')}</div>
            <div className="text-gray-gm">Platform: </div>
            <div className="flex items-center space-x-2 text-black-b1">
              <YoutubeLogoIcon /> <p>YouTube</p>
            </div>
            <div className="text-gray-gm"># of Influencers: </div>
            <div className="text-black-b1">{general.total_influencers}</div>
          </div>
        </div>
        <div className="sm:col-span-1 md:col-span-2">
          <div className="grid sm:grid-cols-1 md:grid-cols-3 gap-3 h-full">
            <div className="relative p-4 rounded-[4px] bg-white">
              {/* <div className="absolute top-4 right-4">
                <LineChart width={100} height={40} data={chartData}>
                  <Line
                    type="monotone"
                    dataKey="audience"
                    stroke={colors.red.r1}
                    strokeWidth={3}
                    dot={false}
                  />
                </LineChart>
              </div> */}
              <div className="flex items-center justify-center w-6 h-6 rounded-[8px] bg-black-b1">
                <UserSmallSolidIcon />
              </div>
              <p className="mt-5 mb-2.5 font-semibold text-2xl text-black-b1">{formatNumber(general.total_subscribers)}</p>
              <p className="text-sm text-black-b1">Total covered audience</p>
            </div>
            <div className="relative p-4 rounded-[4px] bg-white">
              {/* <div className="absolute top-4 right-4">
                <LineChart width={100} height={40} data={chartData}>
                  <Line
                    type="monotone"
                    dataKey="total_views"
                    stroke={colors.yellow.y1}
                    strokeWidth={3}
                    dot={false}
                  />
                </LineChart>
              </div> */}
              <div className="flex items-center justify-center w-6 h-6 rounded-[8px] bg-black-b1">
                <EyeOpenSmallSolidIcon />
              </div>
              <p className="mt-5 mb-2.5 font-semibold text-2xl text-black-b1">{formatNumber(general.total_views)}</p>
              <p className="text-sm text-black-b1">Total views</p>
            </div>
            <div className="relative p-4 rounded-[4px] bg-white">
              {/* <div className="absolute top-4 right-4">
                <LineChart width={100} height={40} data={chartData}>
                  <Line
                    type="monotone"
                    dataKey="avg_views"
                    stroke={colors.green.g1}
                    strokeWidth={3}
                    dot={false}
                  />
                </LineChart>
              </div> */}
              <div className="flex items-center justify-center w-6 h-6 rounded-[8px] bg-black-b1">
                <EyeOpenSmallSolidIcon />
              </div>
              <p className="mt-5 mb-2.5 font-semibold text-2xl text-black-b1">{formatNumber(general.avg_views)}</p>
              <span className="flex items-center text-sm text-black-b1">Average views</span>
            </div>
            <div className="relative p-4 rounded-[4px] bg-white">
              {/* <div className="absolute top-4 right-4">
                <LineChart width={100} height={40} data={chartData}>
                  <Line
                    type="monotone"
                    dataKey="score"
                    stroke={colors.blue.b1}
                    strokeWidth={3}
                    dot={false}
                  />
                </LineChart>
              </div> */}
              <div className="flex items-center justify-center w-6 h-6 rounded-[8px] bg-black-b1">
                <FlagSmallSolidIcon />
              </div>
              <p className="mt-5 mb-2.5 font-semibold text-2xl text-black-b1">{formatNumber(general.avg_engagement_score, '0.[00]%', 'float')}</p>
              <p className="text-sm text-black-b1">Avg Engagement Score</p>
            </div>
            <div className="relative p-4 rounded-[4px] bg-white">
              {/* <div className="absolute top-4 right-4">
                <LineChart width={100} height={40} data={chartData}>
                  <Line
                    type="monotone"
                    dataKey="likes"
                    stroke={colors.violet.v1}
                    strokeWidth={3}
                    dot={false}
                  />
                </LineChart>
              </div> */}
              <div className="flex items-center justify-center w-6 h-6 rounded-[8px] bg-black-b1">
                <HeartSmallSolidIcon />
              </div>
              <p className="mt-5 mb-2.5 font-semibold text-2xl text-black-b1">{formatNumber(general.total_likes)}</p>
              <p className="text-sm text-black-b1">Likes</p>
            </div>
            <div className="relative p-4 rounded-[4px] bg-white">
              {/* <div className="absolute top-4 right-4">
                <LineChart width={100} height={40} data={chartData}>
                  <Line
                    type="monotone"
                    dataKey="comments"
                    stroke={colors.pink.p1}
                    strokeWidth={3}
                    dot={false}
                  />
                </LineChart>
              </div> */}
              <div className="flex items-center justify-center w-6 h-6 rounded-[8px] bg-black-b1">
                <MessageSmallSolidIcon />
              </div>
              <p className="mt-5 mb-2.5 font-semibold text-2xl text-black-b1">{formatNumber(general.total_comments)}</p>
              <p className="text-sm text-black-b1">Comments</p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 p-6 rounded-[4px] bg-white">
        <div className="grid gap-4 sm:grid-cols-3 md:grid-cols-6">
          <div className="flex items-center sm:justify-center border-r-0 border-gray-g2 sm:border-r">
            <div className="sm:max-md:flex sm:max-md:flex-col sm:max-md:items-center">
              <p className="mb-2.5 font-semibold text-2xl text-black-b1">{general.total_influencers}</p>
              <p className="text-sm leading-6 text-gray-gm">Influencers</p>
            </div>
          </div>
          <div className="flex items-center sm:justify-center border-r-0 border-gray-g2 sm:border-r">
            <div className="sm:max-md:flex sm:max-md:flex-col sm:max-md:items-center">
              <p className="mb-2.5 font-semibold text-2xl text-black-b1">{general.total_posts}</p>
              <p className="text-sm leading-6 text-gray-gm">Posts</p>
            </div>
          </div>
          <div className="flex items-center sm:justify-center border-r-0 border-gray-g2 md:border-r">
            <div className="sm:max-md:flex sm:max-md:flex-col sm:max-md:items-center">
              <p className="mb-2.5 font-semibold text-2xl text-black-b1">{formatNumber(general.total_cost, '$0,0.[000]a', 'float')}</p>
              <p className="text-sm leading-6 text-gray-gm">Total spend</p>
            </div>
          </div>
          <div className="flex items-center sm:justify-center border-r-0 border-gray-g2 sm:border-r">
            <div className="truncate sm:max-md:flex sm:max-md:flex-col sm:max-md:items-center">
              <p className="mb-2.5 font-semibold text-2xl text-black-b1">{formatNumber(general.cost_per_video, '$0,0.[000]a', 'float')}</p>
              <p className="text-sm leading-6 truncate text-gray-gm">Cost per video</p>
            </div>
          </div>
          <div className="flex items-center sm:justify-center border-r-0 border-gray-g2 sm:border-r">
            <div className="truncate sm:max-md:flex sm:max-md:flex-col sm:max-md:items-center">
              <p className="mb-2.5 font-semibold text-2xl text-black-b1">{formatNumber(general.cost_per_video_view, '$0,0.[000]a', 'float')}</p>
              <p className="text-sm leading-6 truncate text-gray-gm">Cost per video view</p>
            </div>
          </div>
          <div className="flex items-center sm:justify-center">
            <div className="truncate sm:max-md:flex sm:max-md:flex-col sm:max-md:items-center">
              <p className="mb-2.5 font-semibold text-2xl text-black-b1">{formatNumber(general.cost_per_video_engagement, '$0,0.[0]a', 'float')}</p>
              <p className="text-sm leading-6 truncate text-gray-gm">Cost per engagement</p>
            </div>
          </div>
        </div>

        <p className="mt-11 text-base font-semibold truncate text-black-b1">Campaign Performance over time</p>

        <div className="mt-6">
          <ResponsiveContainer width={'100%'} height={200}>
            <LineChart
              data={parsedPerformanceChartData}
              margin={{
                top: 15,
                right: 5,
                left: performanceLeftMargin,
                bottom: 5,
              }}
            >
              <CartesianGrid vertical={false} stroke={colors.gray.gm} strokeDasharray="2 5" />
              <XAxis
                dataKey="date"
                type="number"
                domain={['auto', 'auto']}
                scale="time"
                axisLine={false}
                tickLine={false}
                ticks={lineChartTicks}
                // interval={4}
                tick={<CustomizedXAxisTick format={(date) => moment.unix(date).format('MMM DD')} fontSize="10px" fill={colors.gray.gm} />}
              />
              <YAxis
                type="number"
                domain={['auto', 'auto']}
                axisLine={false}
                tickLine={false}
                tickMargin={6}
                tick={<CustomizedYAxisTick fontSize="10px" fill={colors.gray.gm} format={(y: any) => formatNumber(y, '0.0a')} />}
              />
              <Tooltip content={<CustomTooltip />} />
              <Line
                // type="monotone"
                dataKey="video_views"
                stroke={colors.blue.b1}
                strokeWidth={3}
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="mt-6 grid grid-cols-1 md:grid-cols-5 xl:grid-cols-4 gap-6">
        <div className="md:max-xl:col-span-2 p-6 pr-0 rounded-[4px] bg-white">
          <p className="pr-6 text-base font-semibold text-black-b1">Views</p>
          <ViewsPieChart className="mr-6" data={pieData} dataKey="video_views" />
          <div className="h-[176px] mr-3 mt-2 space-y-3 overflow-y-auto">
            {pieData.map((d: any, i: number) => (
              <div key={i} className="flex items-center justify-between pr-3">
                <div className="flex items-center space-x-3">
                  <span className="w-2 h-2 rounded-full" style={{ backgroundColor: d.color }}></span>
                  <p className="text-xs text-black-b1">{d.channel_name}</p>
                </div>
                <p className="text-xs text-black-b1">{formatNumber(d.video_views)}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="md:col-span-3 xl:col-span-3 relative p-6 rounded-[4px] bg-white">
          <p className="text-base font-semibold text-black-b1">True Reach</p>
          <div className="mt-11">
            <ReportBarChart
              data={pieData}
              dataKey="true_reach"
              xDomain={[0, (dataMax: any) => closestNumber(+dataMax, 10)]}
              tickCount={7}
              xFormat={(x) => formatNumber(x) + '%'}
            />
          </div>
        </div>
      </div>

      <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="relative p-6 rounded-[4px] bg-white">
          <p className="text-base font-semibold text-black-b1">Engagement</p>
          <div className="mt-11">
            <ReportBarChart
              data={pieData}
              dataKey="engagement_score"
              xDomain={[0, (dataMax: any) => closestNumber(+dataMax, 5)]}
              tickCount={6}
              xFormat={(x) => formatNumber(x) + '%'}
            />
          </div>
        </div>
        <div className="relative p-6 rounded-[4px] bg-white">
          <p className="text-base font-semibold text-black-b1">CPM</p>
          <div className="mt-11">
            <ReportBarChart
              data={pieData}
              dataKey="cpm"
              xDomain={[0, (dataMax: any) => closestNumber(+dataMax, 10)]}
              tickCount={5}
              xFormat={(x) => '$' + formatNumber(x)}
            />
          </div>
        </div>
      </div>

      <div className="mt-6 max-h-[400px] flex flex-col overflow-hidden">
        <div className="flex flex-col min-w-full align-middle overflow-hidden">
          <Table columns={columns} data={tableData} />
        </div>
      </div>
    </div>
  );
};

export const TwitchReport: FC = () => {
  const campaign = useAppSelector(selectCampaignClient);
  const { general, statsByChannels, tableData } = useAppSelector(selectCampaignReportDataClient);

  const pieData = useMemo(
    () =>
      orderBy(
        statsByChannels.map((d: any) => ({
          ...d,
          subscribers: +d.subscribers,
          peak_viewers: +d.peak_viewers,
          avg_viewers: +d.avg_viewers,
          color: randomColor(),
        })),
        (d: any) => d.avg_viewers,
        ['desc']
      ),
    [statsByChannels]
  );

  const columns = useMemo(() => twitchColumns, []);

  return (
    <div className="p-6 pt-0 overflow-auto">
      <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-3">
        <div className="p-6 rounded-[4px] bg-white">
          <p className="text-base font-semibold text-black-b1">General info</p>
          <div className="mt-10 grid grid-cols-2 gap-6 text-sm leading-6">
            <div className="text-gray-gm">Campaign Name:</div>
            <div className="text-black-b1 truncate" title={campaign.campaign_name}>
              {campaign.campaign_name}
            </div>
            <div className="text-gray-gm">Report Date: </div>
            <div className="text-black-b1">{moment().format('MMM-DD-YYYY')}</div>
            <div className="text-gray-gm">Platform: </div>
            <div className="flex items-center space-x-2 text-black-b1">
              <TwitchLogoIcon /> <p>Twitch</p>
            </div>
            <div className="text-gray-gm"># of Streamers: </div>
            <div className="text-black-b1">{general.total_streamers}</div>
          </div>
        </div>
        <div className="sm:col-span-1 md:col-span-2">
          <div className="grid sm:grid-cols-1 md:grid-cols-3 gap-3 h-full">
            <div className="relative p-4 rounded-[4px] bg-white">
              {/* <div className="absolute top-4 right-4">
                <LineChart width={100} height={40} data={chartData}>
                  <Line
                    type="monotone"
                    dataKey="audience"
                    stroke={colors.red.r1}
                    strokeWidth={3}
                    dot={false}
                  />
                </LineChart>
              </div> */}
              <div className="flex items-center justify-center w-6 h-6 rounded-[8px] bg-black-b1">
                <UserSmallSolidIcon />
              </div>
              <p className="mt-5 mb-2.5 font-semibold text-2xl text-black-b1">{formatNumber(general.total_subscribers)}</p>
              <p className="text-sm text-black-b1">Total covered audience</p>
            </div>
            <div className="relative p-4 rounded-[4px] bg-white">
              {/* <div className="absolute top-4 right-4">
                <LineChart width={100} height={40} data={chartData}>
                  <Line
                    type="monotone"
                    dataKey="total_views"
                    stroke={colors.yellow.y1}
                    strokeWidth={3}
                    dot={false}
                  />
                </LineChart>
              </div> */}
              <div className="flex items-center justify-center w-6 h-6 rounded-[8px] bg-black-b1">
                <AchievementSmallSolidIcon />
              </div>
              <p className="mt-5 mb-2.5 font-semibold text-2xl text-black-b1">{formatNumber(general.avg_peak_viewers)}</p>
              <p className="text-sm text-black-b1">Avg. peak views</p>
            </div>
            <div className="relative p-4 rounded-[4px] bg-white">
              {/* <div className="absolute top-4 right-4">
                <LineChart width={100} height={40} data={chartData}>
                  <Line
                    type="monotone"
                    dataKey="avg_views"
                    stroke={colors.green.g1}
                    strokeWidth={3}
                    dot={false}
                  />
                </LineChart>
              </div> */}
              <div className="flex items-center justify-center w-6 h-6 rounded-[8px] bg-black-b1">
                <EyeOpenSmallSolidIcon />
              </div>
              <p className="mt-5 mb-2.5 font-semibold text-2xl text-black-b1">{formatNumber(general.avg_viewers)}</p>
              <span className="flex items-center text-sm text-black-b1">Average viewers</span>
            </div>
            <div className="relative p-4 rounded-[4px] bg-white">
              {/* <div className="absolute top-4 right-4">
                <LineChart width={100} height={40} data={chartData}>
                  <Line
                    type="monotone"
                    dataKey="score"
                    stroke={colors.blue.b1}
                    strokeWidth={3}
                    dot={false}
                  />
                </LineChart>
              </div> */}
              <div className="flex items-center justify-center w-6 h-6 rounded-[8px] bg-black-b1">
                <UserSmallSolidIcon />
              </div>
              <p className="mt-5 mb-2.5 font-semibold text-2xl text-black-b1">{formatNumber(general.total_streamers)}</p>
              <p className="text-sm text-black-b1">Streamers</p>
            </div>
            <div className="relative p-4 rounded-[4px] bg-white">
              {/* <div className="absolute top-4 right-4">
                <LineChart width={100} height={40} data={chartData}>
                  <Line
                    type="monotone"
                    dataKey="likes"
                    stroke={colors.violet.v1}
                    strokeWidth={3}
                    dot={false}
                  />
                </LineChart>
              </div> */}
              <div className="flex items-center justify-center w-6 h-6 rounded-[8px] bg-black-b1">
                <PlaySmallSolidIcon />
              </div>
              <p className="mt-5 mb-2.5 font-semibold text-2xl text-black-b1">{formatNumber(general.total_streams)}</p>
              <p className="text-sm text-black-b1">Streams</p>
            </div>
            <div className="relative p-4 rounded-[4px] bg-white">
              {/* <div className="absolute top-4 right-4">
                <LineChart width={100} height={40} data={chartData}>
                  <Line
                    type="monotone"
                    dataKey="comments"
                    stroke={colors.pink.p1}
                    strokeWidth={3}
                    dot={false}
                  />
                </LineChart>
              </div> */}
              <div className="flex items-center justify-center w-6 h-6 rounded-[8px] bg-black-b1">
                <ClockSmallSolidIcon />
              </div>
              <p className="mt-5 mb-2.5 font-semibold text-2xl text-black-b1">
                {`${formatNumber(general.avg_stream_duration != null ? +general.avg_stream_duration / 3600 : null)} h`}
              </p>
              <p className="text-sm text-black-b1">Average stream duration</p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 grid grid-cols-1 md:grid-cols-5 xl:grid-cols-4 gap-6">
        <div className="md:max-xl:col-span-2 p-6 pr-0 rounded-[4px] bg-white">
          <p className="pr-6 text-base font-semibold text-black-b1">Average Viewers</p>
          <ViewsPieChart className="mr-6" data={pieData} dataKey="avg_viewers" />
          <div className="h-[176px] mr-3 mt-2 space-y-3 overflow-y-auto">
            {pieData.map((d: any, i: number) => (
              <div key={i} className="flex items-center justify-between pr-3">
                <div className="flex items-center space-x-3">
                  <span className="w-2 h-2 rounded-full" style={{ backgroundColor: d.color }}></span>
                  <p className="text-xs text-black-b1">{d.channel_name}</p>
                </div>
                <p className="text-xs text-black-b1">{formatNumber(d.avg_viewers)}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="md:col-span-3 xl:col-span-3 relative p-6 rounded-[4px] bg-white">
          <p className="text-base font-semibold text-black-b1">Peak views</p>
          <div className="mt-11">
            <ReportBarChart
              data={pieData}
              dataKey="peak_viewers"
              xDomain={[0, (dataMax: any) => closestNumber(+dataMax, 10)]}
              tickCount={7}
              xFormat={(x) => formatNumber(x)}
            />
          </div>
        </div>
      </div>

      <div className="mt-6 max-h-[400px] flex flex-col overflow-hidden">
        <div className="flex flex-col min-w-full align-middle overflow-hidden">
          <Table columns={columns} data={tableData} />
        </div>
      </div>
    </div>
  );
};

interface ITableProps {
  data: any[];
  columns: any[];
}

const Table: FC<ITableProps> = memo(
  ({ columns, data }) => {
    const dispatch = useAppDispatch();
    const platform = useAppSelector(selectCampaignReportSettingsPlatformClient);
    const tableFilters = useAppSelector(selectCampaignReportSettingsTableFilterClient);
    const tfSortBy = useAppSelector(selectCampaignReportSettingsTableFilterSortByClient);
    const tfResizing = useAppSelector(selectCampaignReportSettingsTableFilterResizingClient);

    const tableContainerRef = useRef<HTMLDivElement>(null);

    const defaultColumn = useMemo(
      () => ({
        minWidth: 130,
        // width: 150,
        // maxWidth: 400,
      }),
      []
    );

    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      footerGroups,
      rows,
      prepareRow,
      // @ts-ignore
      state: { sortBy, columnResizing },
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        // @ts-ignore
        initialState: { sortBy: tfSortBy },
        useControlledState: (state) => {
          return useMemo(() => {
            return {
              ...state,
              columnResizing: {
                // @ts-ignore
                ...state?.columnResizing,
                // @ts-ignore
                columnWidths: state?.columnResizing?.headerIdWidths
                  ? // @ts-ignore
                    {
                      ...tfResizing,
                      // @ts-ignore
                      ...state.columnResizing?.columnWidths,
                    }
                  : tfResizing,
              },
            };
            // @ts-ignore
          }, [state, tfResizing]);
        },
      },
      useSortBy,
      useResizeColumns,
      useBlockLayout
    );

    // TODO make better update tableFilters. May be use the getSortByToggleProps hook to extend its functionality
    useLegacyEffect(() => {
      dispatch(
        campaignClientActions.setReportSettings({
          tableFilters: {
            ...tableFilters,
            [platform]: {
              ...tableFilters[platform],
              sortBy,
            },
          },
        })
      );
    }, [sortBy]);

    useLegacyEffect(() => {
      if (columnResizing?.columnWidths && !columnResizing?.isResizingColumn && !isEqual(tfResizing, columnResizing.columnWidths))
        dispatch(
          campaignClientActions.setReportSettings({
            tableFilters: {
              ...tableFilters,
              [platform]: {
                ...tableFilters[platform],
                resizing: columnResizing.columnWidths,
              },
            },
          })
        );
    }, [columnResizing?.columnWidths]);

    // Virtualizing is optional, but might be necessary if we are going to potentially have hundreds or thousands of rows
    const rowVirtualizer = useVirtual({
      parentRef: tableContainerRef,
      size: rows.length,
      overscan: 10,
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;
    const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
    const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

    // Render the UI for your table
    return (
      <div ref={tableContainerRef} className="overflow-auto shadow rounded-[4px]">
        <div {...getTableProps()} className="table pr-40">
          <div className="thead sticky top-0 z-30 bg-gray-gd rounded-tl-[4px]">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map((column: any, hidx: number) => (
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    title=""
                    className={cn(
                      'td group text-left text-xs leading-6 whitespace-nowrap font-normal text-white border-r border-gray-g2 last:border-r-0 hover:bg-gray-g3',
                      hidx === 0 ? '!sticky left-0 top-0 z-10 bg-gray-gd shadow-fixed-divider-header' : '',
                      column?.headerClassName || '',
                      column.isSorted ? 'bg-gray-g3' : ''
                    )}
                  >
                    <div className={cn('flex items-center justify-between px-4 py-2 space-x-1', column?.disableSortBy ? '' : 'cursor-pointer')}>
                      {typeof column.Header === 'string' ? <TableHeaderTruncate name={column.Header} /> : column.render('Header')}
                      <div className="flex items-center space-x-2">
                        {column.tooltip && <PopupDescription content={column.tooltip} />}
                        <TableHeaderSort column={column} />
                      </div>
                    </div>
                    <TableResizer column={column} />
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className="tbody bg-white">
            {paddingTop > 0 && (
              <div className="tr">
                <div className="td" style={{ height: `${paddingTop}px` }} />
              </div>
            )}
            {virtualRows.map((virtualRow: any) => {
              const row = rows[virtualRow.index];
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className="tr relative group h-[3.25rem] border-b border-gray-g2 last:border-b-0 hover:shadow-s3 hover:z-20">
                  {row.cells.map((cell: any, bidx: number) => {
                    return (
                      <div
                        {...cell.getCellProps()}
                        className={cn(
                          'td relative !flex items-center truncate whitespace-nowrap px-4 text-sm leading-6 font-normal text-black-b1 border-r border-gray-g2 last:border-r-0',
                          bidx === 0 ? 'sticky left-0 top-0 z-10 shadow-fixed-divider bg-white' : '',
                          cell.column?.className || ''
                        )}
                      >
                        {cell.render('Cell')}
                      </div>
                    );
                  })}
                </div>
              );
            })}
            {paddingBottom > 0 && (
              <div className="tr">
                <div className="td" style={{ height: `${paddingBottom}px` }} />
              </div>
            )}
          </div>
          <div className="tfoot sticky bottom-0 z-30 bg-white">
            {footerGroups.map((group) => (
              <div {...group.getFooterGroupProps()} className="tr h-[3.25rem] shadow-s4">
                {group.headers.map((column, fidx) => (
                  <div
                    {...column.getFooterProps()}
                    className={cn(
                      'td !flex items-center whitespace-nowrap px-4 text-sm leading-6 font-semibold text-violet-v1 border-r border-gray-g2 last:border-r-0',
                      fidx === 0 ? 'sticky left-0 top-0 z-10 bg-white shadow-fixed-divider' : ''
                    )}
                  >
                    {column.render('Footer')}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  },
  (prev, next) => isEqual(prev, next)
);

export const reportChartData: any[] = [
  {
    name: 'Nothernlion',
    views: 20.6,
    true_reach: 23.4,
    engagement: 8,
    cpm: 650,
    color: '#9875FF',
  },
  {
    name: '8-BitRyan',
    views: 25.9,
    true_reach: 4.5,
    engagement: 1.8,
    cpm: 120,
    color: '#80B4FF',
  },
  {
    name: 'Bob Lenon',
    views: 8.1,
    true_reach: 7.8,
    engagement: 2.5,
    cpm: 200,
    color: '#DAE085',
  },
  {
    name: 'Yippe Ki Mr Falcon',
    views: 24.4,
    true_reach: 18.3,
    engagement: 7,
    cpm: 530,
    color: '#FFC775',
  },
  {
    name: 'Lesse Cox',
    views: 13.7,
    true_reach: 26.4,
    engagement: 10,
    cpm: 770,
    color: '#EE77A2',
  },
  {
    name: 'Best Indie Games',
    views: 27.3,
    true_reach: 12.5,
    engagement: 4.9,
    cpm: 350,
    color: '#D582E3',
  },
];

const renderActiveShape = (props: any) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;

  return (
    <g>
      <Sector cx={cx} cy={cy} innerRadius={innerRadius + 0.5} outerRadius={outerRadius - 0.5} startAngle={startAngle} endAngle={endAngle} fill={fill} />
      <Sector cx={cx} cy={cy} startAngle={startAngle} endAngle={endAngle} innerRadius={outerRadius + 4} outerRadius={outerRadius + 8} fill={fill} />
    </g>
  );
};

interface IViewsPieChart {
  data: any[];
  dataKey: string;
  className?: string;
}

export const ViewsPieChart: FC<IViewsPieChart> = ({ data, dataKey, className = '' }) => {
  const [activeIndex, setActiveIndex] = useState<number | number[] | undefined>(undefined);

  const onPieEnter = (e: any, index: number) => {
    setActiveIndex(index);
  };

  const onPieLeave = () => {
    setActiveIndex(undefined);
  };

  return (
    <div className={cn('relative', className)}>
      <ResponsiveContainer width="100%" height={180}>
        <PieChart>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={35}
            // outerRadius={80}
            paddingAngle={1}
            fill="#8884d8"
            dataKey={dataKey}
            onMouseEnter={onPieEnter}
            onMouseLeave={onPieLeave}
          >
            {data.map((entry: any, i: number) => (
              <Cell key={`cell-${i}`} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip
            wrapperStyle={{ zIndex: 1000 }}
            cursor={{ fill: colors.gray.g1 }}
            content={<CustomTooltipForPieChart />}
            allowEscapeViewBox={{ x: true, y: true }}
          />
        </PieChart>
      </ResponsiveContainer>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-6 h-6 flex items-center justify-center rounded-full bg-black-b1">
        <UserSmallSolidIcon />
      </div>
    </div>
  );
};

interface IReportBarChartProps {
  data: any[];
  dataKey: string;
  xDomain: any[];
  ticks?: number[];
  tickCount?: number;
  xFormat?: (x: any) => void;
}

type Sorting = 'asc' | 'desc';

export const ReportBarChart: FC<IReportBarChartProps> = ({ data = [], dataKey = 'value', xDomain = [], xFormat, ticks = [], tickCount = 5 }) => {
  const [sorting, setSorting] = useState<Sorting>('desc');

  const handleSorting = useCallback((value: Sorting) => setSorting(value), []);

  const isDesc = sorting === 'desc';

  const sortedData = useMemo(() => orderBy(data, [dataKey], [sorting]), [data, dataKey, sorting]);

  return (
    <>
      <div className="absolute top-6 right-6 flex rounded border border-gray-g2">
        <span
          className={cn('w-[2.625rem] h-[2.625rem] flex items-center justify-center rounded-tl rounded-bl cursor-pointer', !isDesc ? 'bg-blue-bl' : 'bg-white')}
          onClick={() => handleSorting('asc')}
        >
          <AscendingChartIcon pathFill={!isDesc ? colors.blue.b1 : colors.black.b1} />
        </span>
        <span
          className={cn('w-[2.625rem] h-[2.625rem] flex items-center justify-center rounded-tr rounded-br cursor-pointer', isDesc ? 'bg-blue-bl' : 'bg-white')}
          onClick={() => handleSorting('desc')}
        >
          <DescendingChartIcon pathFill={isDesc ? colors.blue.b1 : colors.black.b1} />
        </span>
      </div>
      <div className="h-[300px] overflow-y-auto overflow-x-hidden">
        <ResponsiveContainer width="100%" height={sortedData.length * 40 > 300 ? sortedData.length * 40 : 300}>
          <BarChart
            data={sortedData}
            layout="vertical"
            margin={{
              top: 10,
              right: 12,
              left: 80,
              bottom: -25,
            }}
          >
            <Tooltip cursor={{ fill: colors.gray.g1 }} content={<CustomTooltipForBarChart />} />
            <CartesianGrid horizontal={false} stroke={colors.gray.gm} strokeDasharray="2 5" />
            <XAxis
              type="number"
              domain={xDomain}
              axisLine={false}
              tickLine={false}
              // ticks={ticks}
              tickCount={tickCount}
              // tickFormatter={(x) => x + '%'}
              tick={<CustomizedXAxisTick show={false} format={xFormat} fill={colors.gray.gm} />}
            />
            <YAxis
              type="category"
              dataKey="channel_name"
              axisLine={false}
              tickLine={false}
              // tick={{
              //   fill: colors.black.b1,
              //   fontSize: '12px',
              // }}
              tick={<CustomizedYAxisTick fill={colors.black.b1} />}
              tickMargin={10}
            />
            <Bar dataKey={dataKey} fill={colors.blue.b1} barSize={12} radius={[4, 4, 4, 4]} />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <ResponsiveContainer width="100%" height={20}>
        <BarChart
          data={sortedData}
          layout="vertical"
          margin={{
            top: 0,
            right: 12,
            left: 80,
            bottom: 5,
          }}
        >
          <XAxis
            type="number"
            domain={xDomain}
            axisLine={false}
            tickLine={false}
            tickCount={tickCount}
            tick={<CustomizedXAxisTick format={xFormat} fill={colors.gray.gm} />}
          />
          <YAxis type="category" dataKey="channel_name" axisLine={false} tickLine={false} tick={false} tickMargin={10} />
          <Bar dataKey={dataKey} />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

interface ICustomizedAxisTickProps {
  format?: (value: any) => void;
  [prop: string]: any;
}

export const CustomizedXAxisTick: FC<ICustomizedAxisTickProps> = (props: any) => {
  const { x, y, payload, format = (x: any) => x, fill = colors.gray.gm, fontSize = '12px', show = true } = props;

  if (!show) return <g transform={`translate(${x},${y})`}></g>;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={20} textAnchor="middle" fill={fill} fontSize={fontSize}>
        <tspan style={{ whiteSpace: 'nowrap' }}>{format(payload.value)}</tspan>
      </text>
    </g>
  );
};

export const CustomizedYAxisTick: FC<ICustomizedAxisTickProps> = (props: any) => {
  const { x, y, payload, format = (y: any) => y, fill = colors.gray.gm, fontSize = '12px' } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={3} textAnchor="end" fill={fill} fontSize={fontSize}>
        <tspan style={{ whiteSpace: 'nowrap' }}>{format(payload.value)}</tspan>
      </text>
    </g>
  );
};

export const CustomTooltip = (props: any) => {
  const { active, payload, label } = props;
  if (active && payload && payload.length) {
    return (
      <div className="shadow p-2 bg-white">
        <div className="flex items-center space-x-2">
          <p className="text-gray-g3">Date:</p>
          <p className="text-gray-gm">{moment.unix(label).format('MMM DD')}</p>
        </div>
        <div className="flex items-center space-x-2">
          <p className="text-gray-g3">Video Views:</p>
          <p className="text-gray-gm">{payload[0].value}</p>
        </div>
      </div>
    );
  }

  return null;
};

export const CustomTooltipForBarChart = (props: any) => {
  const { active, payload, label } = props;
  if (active && payload && payload.length) {
    return (
      <div className="shadow p-2 bg-white">
        <div className="flex items-center space-x-2">
          <p className="text-gray-g3">{label}</p>
          <p className="text-gray-gm">{formatNumber(payload[0].value, undefined, 'float')}</p>
        </div>
      </div>
    );
  }

  return null;
};

const CustomTooltipForPieChart = (props: any) => {
  const { active, payload } = props;

  if (active && payload && payload.length) {
    const data = payload[0].payload || '';
    return (
      <div className="shadow px-6 py-2 text-center bg-white">
        <p className="whitespace-nowrap text-gray-g3">{data.channel_name}</p>
        <div className="flex justify-center space-x-1 text-gray-g3">
          <p>{formatNumber(payload[0].value)}</p>
          <p>{data.video_views_ratio ? ` (${formatNumber(data.video_views_ratio, '0.[00]%', 'float')})` : ''}</p>
        </div>
      </div>
    );
  }

  return null;
};
